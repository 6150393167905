import axios from 'axios';
import { getToken } from './user';
import { REACT_APP_BASE_URL } from '../constants';

const api = axios.create({
  baseURL: `${REACT_APP_BASE_URL}`,
});

api.interceptors.request.use((config) => {
  const newConfig = config;
  const token = getToken();
  if (token) {
    newConfig.headers.Authorization = token;
  }
  return newConfig;
});

export default api;
