import React, { useEffect } from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from 'styled-components';
import { AuthProvider } from '../hooks/AuthContext';
import Routes from '../routes';
import { GlobalStyle } from '../styles';
import { themeUnimedPay } from '../theme';

import { changeFavicon } from '../utils';

const queryClient = new QueryClient();

const App: React.FC = () => {
  useEffect(() => {
    changeFavicon('./unimedFavicon.svg');
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={themeUnimedPay}>
        <GlobalStyle />
        <AuthProvider>
          <Routes />
          <ToastContainer
            position="bottom-center"
            newestOnTop
            transition={Slide}
            hideProgressBar
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
