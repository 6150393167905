import React, { useEffect } from 'react';
import { Route as Component, RouteProps } from 'react-router-dom';

interface Props extends RouteProps {
  title?: string;
}

const Route: React.FC<Props> = (props) => {
  const { title } = props;

  const iconHeart = '💚';
  const mainTitle = 'Meu negócio';
  const companyName = 'UnimedPay';

  useEffect(() => {
    document.title = title
      ? `${iconHeart} ${mainTitle} | ${title} | ${companyName}`
      : `${iconHeart} ${mainTitle} | ${companyName}`;
  }, [title]);

  return <Component {...props} />;
};

export default Route;
