/* eslint-disable import/no-cycle */

export interface Colors {
  background: string;
  primary: string;
  primaryTransparent: string;
  lightBlue: string;
  secondaryLightBlue: string;
  tertiaryLightBlue: string;
  quarterLightBlue: string;
  fifthLightBlue: string;
  white: string;
  lightGray: string;
  secondaryGray: string;
  tertiaryGray: string;
  gray: string;
  error: string;
  disabled: string;
  secondGray: string;
  green: string;
  orange: string;
  orangeLight: string;
  iconColor: string;
}

export const colorsUnimedPay: Colors = {
  background: '#F1F1F1',
  primary: '#00995D',
  primaryTransparent: 'rgba(0, 153, 93, 0.35)',
  lightBlue: '#9be169',
  secondaryLightBlue: '#9be16945',
  tertiaryLightBlue: '#F2F9FF',
  quarterLightBlue: '#D9EEFF',
  fifthLightBlue: '#00995D',
  white: '#FFFFFF',
  lightGray: '#ADADAD',
  secondaryGray: '#AFAFAF',
  tertiaryGray: '#8D8D8D',
  gray: '#E5E5E5',
  error: '#D93025',
  disabled: 'rgba(0, 0, 0, 0.14)',
  secondGray: '#F8F8F8',
  green: '#56D59A',
  orange: '#FFC250',
  orangeLight: '#FFF7E8',
  iconColor: '#00995D',
};

const colors = colorsUnimedPay;
export { colors };
