import React, {
  useState,
  useEffect,
  createContext,
  useContext,
} from 'react';

import { LoginData } from '../pages/Login/types';
import api from '../services/api';
import {
  setEcList,
  getToken,
  removeUser,
  setUser,
  Ec,
  getEc,
  getUser,
  setUserFirstAccess,
} from '../services/user';

interface AuthContextProps {
  authenticated: boolean;
  loading: boolean;
  login: (form: LoginData) => Promise<boolean>;
  logout: () => void;
  firstAccess: (document: string) => Promise<boolean>;
  allowQ2Link: number;
  setAllowQ2Link: React.Dispatch<React.SetStateAction<number>>;
  ecListState: Ec[];
  ecSelectedState: Ec | undefined;
  setEcSelectedState: (param: Ec) => void;
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [token, setToken] = useState<string | null>(null);
  const [allowQ2Link, setAllowQ2Link] = useState<number>(
    /**
   * Possible values for Enable Q2Link.
   * @enum {number}
   * UNAUTHORIZED: -2 - EC rejected for lack of transactions;
   * REFUSED: -1 - Permission request from EC, but refused by Q2;
   * DISABLE: 0 - EC doesn't have option;
   * ACTIVE: 1 - EC ready for create links;
   * AUTHORIZED: 2 - Q2 Permission granted and waiting EC accept;
   * REQUESTED: 3 - EC requested Q2Link activation;
   * APROVED: 4 - Q2 Permission granted and waiting first access;
   */
    0, // Init with a desactivated value
  );
  const [ecListState, setEcListState] = useState<Ec[]>([]);
  const [ecSelectedState, setEcSelectedState] = useState<Ec>();

  useEffect(() => {
    setAllowQ2Link(allowQ2Link);
  }, [allowQ2Link]);

  const signIn = (form: LoginData) => api.post('/login', form).then(
    (res) => {
      if (res.data.Message) {
        return false;
      }
      setEcList(res.data.user.ecs);
      setUser({
        name: ecSelectedState?.EventName || res.data.user.ecs[0].EventName,
        token: res.data.user.token,
      });
      setEcListState(res.data.user.ecs);
      setToken(res.data.user.token);

      return true;
    },
    () => false,
  );

  const signOut = () => {
    removeUser();
    setToken(null);
  };

  const firstAccess = (document: string) => api.get(`verify-first-access/${document}`).then(
    (res) => {
      setUserFirstAccess({
        isFirstAccess: res.data.isFirstAccess,
        document,
      });

      return res.data.isFirstAccess;
    },
    () => false,
  );

  useEffect(() => {
    if (getToken() && !getEc()) { // Prevent old connections
      signOut();
      setLoading(false);
      return;
    }
    setToken(getToken());
    setLoading(false);
    if (getToken() && getUser()) {
      const newUser = getUser();
      if (newUser) {
        if (newUser.q2linkAuthToken) {
          delete newUser.q2linkAuthToken;
          setUser({
            ...newUser,
          });
        }
      }
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authenticated: !!token,
        loading,
        login: signIn,
        logout: signOut,
        firstAccess,
        allowQ2Link,
        setAllowQ2Link,
        ecListState,
        ecSelectedState,
        setEcSelectedState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  return context;
};
