import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../../hooks/AuthContext';
import Logo from '../../assets/icons/Logo';
import api from '../../services/api';
import {
  getEc,
  getUser,
  setUser,
} from '../../services/user';
import {
  Container,
  CardMenuMobile,
  LogoContainer,
  MenuContainer,
  MenuItem,
  SolicitPasswordButtonContainer,
  SolictPasswordButton,
  MenuItemMobile,
  MenuItemMobileDense,
  LogoutItemMobileDense,
  IconContainer,
  MenuItemText,
  MenuItemMobileText,
  Selected,
  MenuMobile,
  MenuMobileIcon,
  DashboardIcon,
  DashboardPrimaryIcon,
  CloseMenuIcon,
  HelpIcon,
  HelpIconPrimary,
  MyDataMenuIcon,
  MyDataButton,
  PaymentTerminalsIcon,
  PaymentTerminalsPrimaryIcon,
  SignOutPrimaryIcon,
  LogoutContainer,
  Logout,
  LogoutText,
  SignOutIcon,
  LogoutButton,
  LinkQ2Link,
  SpinnerContainer,
  MyData,
  MyDataContainer,
  MyDataLi,
  MyDataSeparator,
  MyDataMinorText,
  Warning,
  MyDataIcon,
  ReceivablesSchenduleIcon,
  ExtractIcon,
  PaymentLinkIcon,
  ExtractIconMobile,
  MenuItemMobileDenseMultiple,
  ReceivablesSchenduleIconGreen,
  PaymentLinkIconGreen,
} from './styles';
import { MyDataInfo } from './types';
import LoadingSpinner from '../LoadingSpinner';
import { colors } from '../../theme';
import SentPasswordModal from '../SentPasswordModal';
import { REACT_APP_PAYMENT_LINK_URL } from '../../constants';

const Header: React.FC<{
  openedMenu: (value: boolean) => void; closeMenu: boolean
}> = ({ openedMenu, closeMenu }) => {
  const {
    logout,
    allowQ2Link,
    setAllowQ2Link,
    ecSelectedState,
  } = useAuth();
  const location = useLocation();
  const { push } = useHistory();
  const [open, setOpen] = useState<boolean>(false);
  const [pathName, setPathName] = useState<string>('/');
  const [openMyData, setOpenMyData] = useState<boolean>(false);
  const [info, setInfo] = useState<MyDataInfo>({} as MyDataInfo);
  const [loadingMyData, setLoadingMyData] = useState<boolean>(true);
  const [sendingPassword, setSendingPassword] = useState<boolean>(false);
  const [showSentPasswordModal, setShowSentPasswordModal] = useState<boolean>(false);
  const [errorInfo, setErrorInfo] = useState<boolean>(false);

  const handleRedirectQ2Link = async () => {
    const ec = getEc();
    await api.get('/checkq2linkstatus', {
      params: {
        ecId: ec.Id,
      },
    }).then((res) => {
      const { q2LinkStatus, requestId, q2linkAuthToken } = res.data;
      setAllowQ2Link(q2LinkStatus);
      if (requestId) {
        setUser({
          ...getUser(),
          requestId,
        });
      }
      if (q2linkAuthToken) {
        window.open(`${REACT_APP_PAYMENT_LINK_URL}/login/${q2linkAuthToken}`, '_blank');
        return;
      }
      logout();
    });
  };

  const handleClickOut = useCallback((e: MouseEvent) => {
    const element = e.target as HTMLElement;
    if (element.id !== 'my-data-btn'
      && element.id !== 'my-data-ul'
      && element.id !== 'my-data-text'
    ) {
      setOpenMyData(false);
    }
  }, []);

  const handleNavigate = useCallback((route: string) => {
    push(route);
    setOpen(false);
  }, []);

  const getMyData = useCallback(() => {
    setLoadingMyData(true);
    const user = getEc();
    api.get<MyDataInfo>('/about', {
      params: {
        ecId: user.Id,
        franchiseId: user.FranchiseId,
      },
    })
      .then(({ data }) => {
        setInfo(data);
        setLoadingMyData(false);
      })
      .catch(() => {
        setErrorInfo(true);
        setLoadingMyData(false);
      });
  }, []);

  const verifyEc = () => {
    const ecSelected = ecSelectedState || getEc();
    if (!ecSelected && location.pathname !== '/') logout();
  };

  const handleSendPassword = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
    setSendingPassword(true);
    const { Id } = getEc();
    api.get(`/my-passwords/${Id}`)
      .then(() => setShowSentPasswordModal(true))
      .catch(() => toast.error('Eita! Problema ao enviar o e-mail. Tente novamente', {
        position: 'bottom-left',
      }))
      .finally(() => setSendingPassword(false));
  };

  useEffect(() => {
    if (!showSentPasswordModal) setSendingPassword(false);
  }, [showSentPasswordModal]);

  useEffect(() => {
    setPathName(location.pathname);
  }, [location]);

  useEffect(() => {
    setOpen(false);
  }, [closeMenu]);

  useEffect(() => {
    openedMenu(open);
  }, [open]);

  useEffect(() => {
    getMyData();
    document.addEventListener('click', handleClickOut);
    verifyEc();
    return () => {
      document.removeEventListener('click', handleClickOut);
    };
  }, []);

  return (
    <>
      <SentPasswordModal
        setShow={setShowSentPasswordModal}
        show={showSentPasswordModal}
      />
      <Container>
        <LogoContainer to="/">
          <Logo />
        </LogoContainer>
        <MenuContainer hasQ2Link={!!allowQ2Link}>
          <Link to="/dashboard">
            <MenuItem>
              <IconContainer>
                <DashboardIcon />
                <MenuItemText>Início</MenuItemText>
              </IconContainer>
              <Selected hasQ2Link={!!allowQ2Link} active={pathName} />
            </MenuItem>
          </Link>
          <Link to="/payment-terminals">
            <MenuItem>
              <IconContainer>
                <PaymentTerminalsIcon />
                <MenuItemText>Terminais de pagamento</MenuItemText>
              </IconContainer>
            </MenuItem>
          </Link>
          <Link to="/extract">
            <MenuItem>
              <IconContainer>
                <ExtractIcon />
                <MenuItemText>Extrato</MenuItemText>
              </IconContainer>
            </MenuItem>
          </Link>
          <Link to="/schendule-receivable">
            <MenuItem>
              <IconContainer>
                <ReceivablesSchenduleIcon />
                <MenuItemText>Agenda de Recebíveis</MenuItemText>
              </IconContainer>
            </MenuItem>
          </Link>
          {allowQ2Link === -2 && (
            <Link to="/q2link">
              <MenuItem>
                <IconContainer>
                  <PaymentLinkIcon />
                  <MenuItemText>Link de Pagamento</MenuItemText>
                </IconContainer>
              </MenuItem>
            </Link>
          )}
          {allowQ2Link === 4 && (
            <Link to="/q2link">
              <MenuItem>
                <IconContainer>
                  <PaymentLinkIcon />
                  <MenuItemText>Link de Pagamento</MenuItemText>
                </IconContainer>
              </MenuItem>
            </Link>
          )}
          {allowQ2Link === 3 && (
            <Link to="/q2link">
              <MenuItem>
                <IconContainer>
                  <PaymentLinkIcon />
                  <MenuItemText>Link de Pagamento</MenuItemText>
                </IconContainer>
              </MenuItem>
            </Link>
          )}
          {allowQ2Link === 2 && (
            <Link to="/q2link">
              <MenuItem>
                <IconContainer>
                  <PaymentLinkIcon />
                  <MenuItemText>Link de Pagamento</MenuItemText>
                </IconContainer>
              </MenuItem>
            </Link>
          )}
          {allowQ2Link === -1 && (
            <Link to="/q2link">
              <MenuItem>
                <IconContainer>
                  <PaymentLinkIcon />
                  <MenuItemText>Link de Pagamento</MenuItemText>
                </IconContainer>
              </MenuItem>
            </Link>
          )}
          {allowQ2Link === 1 && (
            <LinkQ2Link onClick={() => handleRedirectQ2Link()}>
              <MenuItem>
                <IconContainer>
                  <PaymentLinkIcon />
                  <MenuItemText>Link de Pagamento</MenuItemText>
                </IconContainer>
              </MenuItem>
            </LinkQ2Link>
          )}
          <Link to="/help">
            <MenuItem>
              <IconContainer>
                <HelpIcon />
                <MenuItemText>Ajuda</MenuItemText>
              </IconContainer>
            </MenuItem>
          </Link>
        </MenuContainer>
        <MenuMobile onClick={() => setOpen(!open)}>
          <MenuMobileIcon />
        </MenuMobile>
        <LogoutContainer>
          <MyDataButton
            id="my-data-btn"
            onClick={() => setOpenMyData(!openMyData)}
          >
            <MyDataIcon id="my-data-btn" />
            <MenuItemText id="my-data-text" style={{ maxWidth: 'fit-content' }}>Meus dados</MenuItemText>
          </MyDataButton>
          {!errorInfo && (
            <MyData id="my-data-ul" show={openMyData}>
              {loadingMyData ? (
                <SpinnerContainer>
                  <LoadingSpinner />
                </SpinnerContainer>
              ) : (
                <MyDataContainer>
                  <MyDataLi>E-mail</MyDataLi>
                  <MyDataLi
                    bold
                    style={{
                      textTransform: 'none',
                    }}
                    title={info.email}
                  >
                    {info.email}
                  </MyDataLi>
                  <MyDataSeparator />
                  <MyDataLi>Login Queridona Smart</MyDataLi>
                  <MyDataLi bold>{info.POSLogin}</MyDataLi>
                  <MyDataLi>Senha Queridona Smart</MyDataLi>
                  <MyDataLi bold>{info.POSPassword}</MyDataLi>
                  <MyDataSeparator />
                  <MyDataLi>Senha Administrativa</MyDataLi>
                  <MyDataMinorText>(senha para realização de estorno)</MyDataMinorText>
                  <MyDataLi bold>{info.admPassword}</MyDataLi>
                  <SolicitPasswordButtonContainer onClick={(e) => handleSendPassword(e)}>
                    <SolictPasswordButton disabled={sendingPassword}>
                      {sendingPassword ? (
                        <LoadingSpinner size={12} color={colors.primary} />
                      ) : (
                        <>
                          Solicitar senha
                        </>
                      )}
                    </SolictPasswordButton>
                  </SolicitPasswordButtonContainer>
                </MyDataContainer>
              )}
            </MyData>
          )}
          {errorInfo && (
            <MyData id="my-data-ul" show={openMyData}>
              <SpinnerContainer>
                <Warning />
              </SpinnerContainer>
            </MyData>
          )}
          <Logout to="/" onClick={logout} title="Sair para Login">
            <LogoutText>Sair</LogoutText>
            <LogoutButton type="button">
              <SignOutIcon />
            </LogoutButton>
          </Logout>
        </LogoutContainer>
      </Container>
      <CardMenuMobile open={open}>
        <Link className="m1" to="/">
          <MenuItemMobile>
            <DashboardPrimaryIcon />
            <MenuItemMobileText>Início</MenuItemMobileText>
          </MenuItemMobile>
        </Link>
        <Link className="m2" to="/payment-terminals">
          <MenuItemMobile>
            <PaymentTerminalsPrimaryIcon />
            <MenuItemMobileText>Terminais de pagamento</MenuItemMobileText>
          </MenuItemMobile>
        </Link>
        <Link
          style={{
            gridRowStart: 2,
          }}
          className="m3"
          to="/extract"
        >
          <MenuItemMobile>
            <ExtractIconMobile />
            <MenuItemMobileText>Extrato</MenuItemMobileText>
          </MenuItemMobile>
        </Link>
        {allowQ2Link === -2 && (
          <Link className="m4" to="/q2link">
            <MenuItemMobile>
              <PaymentLinkIconGreen />
              <MenuItemMobileText>Link de Pagamento</MenuItemMobileText>
            </MenuItemMobile>
          </Link>
        )}
        {allowQ2Link === 4 && (
          <Link className="m4" to="/q2link">
            <MenuItemMobile>
              <PaymentLinkIconGreen />
              <MenuItemMobileText>Link de Pagamento</MenuItemMobileText>
            </MenuItemMobile>
          </Link>
        )}
        {allowQ2Link === 3 && (
          <Link className="m4" to="/q2link">
            <MenuItemMobile>
              <PaymentLinkIconGreen />
              <MenuItemMobileText>Link de Pagamento</MenuItemMobileText>
            </MenuItemMobile>
          </Link>
        )}
        {allowQ2Link === 2 && (
          <Link className="m4" to="/q2link">
            <MenuItemMobile>
              <PaymentLinkIconGreen />
              <MenuItemMobileText>Link de Pagamento</MenuItemMobileText>
            </MenuItemMobile>
          </Link>
        )}
        {allowQ2Link === 1 && (
          <LinkQ2Link onClick={() => handleRedirectQ2Link()}>
            <MenuItemMobile>
              <PaymentLinkIconGreen />
              <MenuItemMobileText>Link de Pagamento</MenuItemMobileText>
            </MenuItemMobile>
          </LinkQ2Link>
        )}
        {allowQ2Link === -1 && (
          <Link className="m4" to="/q2link">
            <MenuItemMobile>
              <PaymentLinkIconGreen />
              <MenuItemMobileText>Link de Pagamento</MenuItemMobileText>
            </MenuItemMobile>
          </Link>
        )}
        <Link className="m2" to="/schendule-receivable">
          <MenuItemMobile>
            <ReceivablesSchenduleIconGreen />
            <MenuItemMobileText>Agenda de Recebiveis</MenuItemMobileText>
          </MenuItemMobile>
        </Link>
        {allowQ2Link === 0 ? (
          <>
            <MenuItemMobileDense
              style={{
                gridRowStart: allowQ2Link < 1 ? 3 : 'auto',
              }}
              className="m6"
              onClick={() => handleNavigate('/help')}
            >
              <MenuItemMobileText>Ajuda</MenuItemMobileText>
              <HelpIconPrimary />
            </MenuItemMobileDense>
            <MenuItemMobileDense
              style={{
                gridRowStart: allowQ2Link < 1 ? 3 : 'auto',
              }}
              className="m6"
              onClick={() => handleNavigate('/my-data')}
            >
              <MenuItemMobileText>Meus Dados</MenuItemMobileText>
              <MyDataMenuIcon />
            </MenuItemMobileDense>
          </>
        ) : (
          <MenuItemMobileDenseMultiple>
            <MenuItemMobileDense
              className="m6"
              onClick={() => handleNavigate('/help')}
            >
              <MenuItemMobileText>Ajuda</MenuItemMobileText>
              <HelpIconPrimary />
            </MenuItemMobileDense>
            <MenuItemMobileDense
              style={{
                gridRowStart: allowQ2Link < 1 ? 4 : 'auto',
              }}
              className="m6"
              onClick={() => handleNavigate('/my-data')}
            >
              <MenuItemMobileText>Meus Dados</MenuItemMobileText>
              <MyDataMenuIcon />
            </MenuItemMobileDense>
          </MenuItemMobileDenseMultiple>
        )}
        <MenuItemMobileDense
          style={{
            gridRowStart: allowQ2Link < 1 ? 4 : 'auto',
          }}
          className="m6"
          onClick={() => setOpen(false)}
        >
          <MenuItemMobileText>Fechar Menu</MenuItemMobileText>
          <CloseMenuIcon />
        </MenuItemMobileDense>
        <LogoutItemMobileDense
          style={{
            gridRowStart: allowQ2Link < 1 ? 4 : 'auto',
          }}
          to="/"
          className="m5"
          onClick={logout}
        >
          <MenuItemMobileText>Sair</MenuItemMobileText>
          <SignOutPrimaryIcon />
        </LogoutItemMobileDense>
      </CardMenuMobile>
    </>
  );
};

export default Header;
